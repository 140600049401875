import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import PageTitles from '../components/pagetitles';
import AboutComponent from '../components/AboutComponent';

const AboutPage = () => (
  <Layout pageTitle={PageTitles.About}>
    <SEO title={PageTitles.About} />
    <AboutComponent />
  </Layout>
);

export default AboutPage;
