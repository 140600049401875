import React from 'react';
import store from '../../store';
import './AboutComponent.scss';
import us from '../../images/us.svg'

export default function Mission({}) {

  store.dispatch({ type: 'SETTING', payload: 'light' });

  return (
      <section id='about'>
        <div id='about-mission'>
          <h1>Making wealth accessible for everyone.</h1>
          {/* <p>Mosaic's mission is to make wealth accessible for everyone.</p> */}
        </div>
        <div id='about-philosophy'>
          <img src={us} alt={'Illustration of co-founders Muin and Mike.'}></img>
          <div id='phil-content'>
            <h2>Philosophy</h2>
            <p>Mosaic's core belief is that wealth and financial freedom should be accessible for everyone. With this as our mission, we are building a platform that helps you feel confident in achieving all your financial goals.</p>
            <h2>Who We Are</h2>
            <p>We're <a href="https://twitter.com/guard_if">Mike</a> and <a href="https://twitter.com/MuinMomin">Muin</a>. 👋 If you have any questions, please feel free to reach out to us at <a href="mailto:hello@mosaicmoney.app">hello@mosaicmoney.app</a>!</p>
          </div>
        </div>
      </section>
  );
}
